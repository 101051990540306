<router-outlet></router-outlet>
<div class="container my-5 text-muted text-center">
  Folkwang Vault<br />
  {{ "versionNumber" | i18n: version }}
  <br /><br />
  <div class="small">
    Der Folkwang Vault wird mit Vaultwarden betrieben und nutzt eine angepasste Version des Bitwarden&reg; Web-Vaults.<br>
    Bei technischen Problemen wenden Sie sich bitte an <a href="mailto:servicedesk@folkwang-uni.de">servicedesk@folkwang-uni.de</a>
  </div>
</div>
